<template>
  <div class="form-layout">
    <van-form style="padding-bottom: 30px;" @submit="submit" label-align="left" ref="formData" label-width="90px">
      <van-field
        v-model="formData.name"
        name="姓名"
        label="姓名"
        :required="true"
        placeholder="请填写姓名"
        :rules="[{ required: true }]"
      />

      <van-field
        v-show="formData.isLevyByQJX"
        v-model="formData.bank"
        name="开户银行"
        label="开户银行"
        :required="true"
        placeholder="请填写开户银行"
        :rules="[{ required: formData.isLevyByQJX }]"
      />

      <van-field
        v-model="formData.bankCardNo"
        name="银行卡号"
        label="银行卡号"
        :required="true"
        placeholder="请填写银行卡号"
        :rules="[{ required: true }]"
      />

      <van-field
        v-show="!formData.isLevyByQJX"
        v-model="formData.alipayNo"
        name="支付宝账号"
        label="支付宝账号"
        placeholder="请填写支付宝账号"
      />

      <van-field
        v-show="!formData.isLevyByQJX"
        v-model="formData.wechatNo"
        name="微信号"
        label="微信号"
        placeholder="请填写微信号"
      />

      <van-field
        v-model="formData.mobile"
        name="手机号码"
        label="手机号码"
        type="tel"
        :required="true"
        placeholder="请填写手机号码"
        :rules="[{validator:checkPhone, required: true }]"
      />
      <van-field
        v-model="formData.vcode"
        center
        clearable
        type="digit"
        maxlength="6"
        label="短信验证码"
        name="短信验证码"
        :required="true"
        placeholder="请填写验证码"
        :rules="[{ required: true }]"
      >
        <template #button>
          <van-button class="btn-vcode" v-if="!vcodeLoading" :loading="vcodeLoading" @click="sendVcode" native-type="button" size="small" type="info">获取验证码</van-button>
          <van-button class="btn-vcode" v-else  native-type="button" size="small" type="info" >{{sendCodeText}}秒后重新获取</van-button>
        </template>
      </van-field>

      <van-field
        readonly
        clickable
        name="picker"
        :value="isHmtText"
        label="证件类型"
        :required="true"
        placeholder="点击选择证件类型"
        :rules="[{ required: true}]"
        @click="showCardType = true"
      />

      <van-popup v-model="showCardType" position="bottom">
        <van-picker
          show-toolbar
          value-key="value"
          :columns="idCardArr"
          @confirm="getCardType"
          @cancel="showCardType = false"
        />
      </van-popup>

      <van-field
        v-if="formData.isHmt===0"
        v-model="formData.idCard"
        name="证件号码"
        label="证件号码"
        :required="true"
        placeholder="请填写证件号码"
        :rules="[{ required: true }]"
      />

      <van-field
        v-if="formData.isHmt===1"
        v-model="formData.certNumber"
        name="港澳台证件号"
        label="港澳台证件号"
        :required="true"
        placeholder="请填写港澳台证件号"
        :rules="[{ required: true }]"
      />

      <van-field
        :required="formData.isLevyByQJX || formData.isLevyByCSF"
        name="uploader"
        label="身份证正面"
        v-if="formData.isHmt===0"
        :rules="[{ required: (formData.isLevyByQJX || formData.isLevyByCSF) && idCardFrontPath.length==0, message: '请上传身份证正面图片' }]"
      >
        <template #input>
          <van-uploader accept="image/*" :max-size="10 * 1024 * 1024" @delete="beforeDelete('front')" v-model="idCardFrontPath" :after-read="(file => afterRead(file,'idCardFrontPath'))" :max-count="1"/>
        </template>
      </van-field>
      <van-field
        :required="formData.isLevyByQJX || formData.isLevyByCSF"
        name="uploader"
        label="身份证背面"
        v-if="formData.isHmt===0"
        :rules="[{ required: (formData.isLevyByQJX || formData.isLevyByCSF) && idCardBackPath.length==0, message: '请上传身份证背面图片' }]"
      >
        <template #input>
          <van-uploader accept="image/*" :max-size="10 * 1024 * 1024" @delete="beforeDelete('back')" v-model="idCardBackPath" :after-read="(file => afterRead(file,'idCardBackPath'))" :max-count="1"/>
        </template>
      </van-field>

      <van-field
        v-if="!formData.id || (formData.isLevyByQJX && formData.isSginContract==0)"
        name="radio"
        label="签署合同"
        :required="true"
        :rules="[{ required: true, message: '请选择是否签署合同' }]"
      >
        <template #input>
          <van-radio-group v-model="formData.isSgin" direction="horizontal">
            <van-radio :name="1">是</van-radio>
<!--            <van-radio :name="0" v-if="!formData.isLevyByQJX">否</van-radio>-->
          </van-radio-group>
        </template>
      </van-field>

<!--      字节认证-->
      <!-- <van-field
        v-if="!formData.isLevyByQJX"
        name="liveStatus"
        label="人脸识别"
      >
        <template #input>
          <span v-if="formData.liveStatus == 'true'" class="pass-face">已通过人脸识别</span>
          <span v-else class="wargin-face">还未进行人脸识别,<a class="to-face" @click="checkCanFace">请认证</a></span>
        </template>
      </van-field> -->

<!--      山西活体认证----- 2022年10月8日14:37:06暂时去掉山西主体强制人脸识别 :required="formData.isLevyByQJX"-->
      <!-- <van-field
        v-if="formData.isLevyByQJX"
        name="liveStatus"
        label="人脸识别"
      >
        <template #input>
          <div>
            <span v-if="formData.liveStatus == 'true'" class="pass-face">已通过人脸识别</span>
            <span v-else class="wargin-face">还未进行人脸识别,<a class="to-face" @click="checkCanFace">请认证</a></span>
          </div>
        </template>
      </van-field> -->

      <van-field
        v-if="!formData.id"
        name="用户服务协议"
        label=""
        :border="false"
        class="empty-label"
      >
        <template #input>
          <van-checkbox v-model="server" />
          <span style="margin-left: 10px;font-size:12px;width: 280px">
            已阅读并同意
            《<router-link class="font-style" :to="{path:'/mine/server',query:{name:'安徽一飞信息科技有限公司',address:'安徽省安庆市怀宁县工业园独秀大道北020号科技创新中心315-6室'}}">安徽一飞信息科技有限公司</router-link>》
            《<router-link class="font-style" :to="{path:'/mine/server',query:{name:'河南岑铭信息科技有限公司',address:'河南省驻马店市驿城区雪松大道与乐山大道交叉口向北500米雪松街道办事处三楼301室'}}">河南岑铭信息科技有限公司</router-link>》
            《<router-link class="font-style" :to="{path:'/mine/server',query:{name:'河南澳初信息科技有限公司',address:'河南省驻马店市驿城区雪松大道与乐山大道交叉口向北500米雪松街道办事处三楼303室'}}">河南澳初信息科技有限公司</router-link>》

          </span>
        </template>
      </van-field>

      <div v-if="!!formData.businessLicense">
        <div class="businessLicense">
          <div style="text-align: right; width: 100px;">营业执照</div>
        </div>
        <img :src="formData.businessLicense" @click="onShowImg" style="width: 78%; margin-top: -12px;" alt="">
      </div>

      <div style="margin: 16px;">
        <van-button round block type="info" style="flex: 1;margin-right: 10px;" native-type="submit">
          提 交
        </van-button>
      </div>
    </van-form>
    <footer-menu></footer-menu>

    <!--    查看营业执照大图-->
    <van-popup v-model="isShowImg" style="width: 100%;">
      <img :src="formData.businessLicense" style="width: 100%;" alt="">
    </van-popup>

    <van-dialog v-model="isAuth" :showConfirmButton="false">
      <div class="modal-layer">
        <div class="modal-layer-popup-title">认证提示</div>
        <div class="modal-layer-popup-content">
          您即将前往“会税通”小程序进行人脸认证
        </div>
        <div style="margin: 16px;">
          <div v-show="!isMiniProgramed">
            <wx-open-launch-weapp
              id="launch-btn"
              username="gh_cec8224b00da"
              :path="'pages/verify/info/index?id='+orderNumber"
              @launch="onGoMiniProgramSuccess"
              @error="onGoMiniProgramError"
            >
              <script type="text/wxtag-template">
                <style>
                  .btn {
                    border-radius: 100px;
                    font-size: 16px;
                    color: #fff;
                    background-color: #ee0a24;
                    border: 1px solid #ee0a24;
                    height: 46px !important;
                    line-height: 46px !important;
                    width: 300px;
                    padding: 0 !important;
                    text-align: center;
                    margin: 0 !important;
                  }
                </style>
                <button class="btn">打开小程序</button>
              </script>
            </wx-open-launch-weapp>
          </div>
          <van-button v-show="isMiniProgramed" round block type="info" @click="onAuthEd">
            认证完成
          </van-button>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import footerMenu from '@/components/footer/FooterMenu'
import requestHTAPI from '@/axios/HantangTax'
import requestSXAPI from '@/axios/ShanxiTax'
import Exif from 'exif-js'
import { compress, dataURLtoFile, debounce } from '@/libs/utils'

import wx from 'weixin-js-sdk'

export default {
  name: 'userInfo',
  components: {
    footerMenu
  },
  data () {
    return {
      submitStatus: 'default', // 提交数据时的状态
      isShowImg: false, // 是否展示大图
      vcodeLoading: false, // 发送验证码loading
      timer: null,
      sendCodeText: 59,
      merNo: '',
      formData: {
        name: '',
        isHmt: '',
        isSgin: 1, // 2022年5月9日12:05:51 默认为所有自由职业者均需要签署合同
        isSginContract: 0, // 是否做过电子签手写合同
        idCard: '',
        certNumber: '',
        mobile: '',
        vcode: '',
        bank: '',
        bankCardNo: '',
        idCardFrontPath: '',
        idCardBackPath: '',
        alipayNo: '',
        wechatNo: '',
        businessLicense: '', // 营业执照图片
        liveStatus: false, // 是否已通过人脸认证
        isLevyByQJX: false, // 是否为山西主体
        isLevyByCSF: false // 湖南郴税服
      },
      taxNo: '14280010WK00311', // 山西云税服
      server: false,
      files: {
        name: '',
        type: ''
      },
      isHmtText: '',
      idCardFrontPath: [],
      idCardBackPath: [],
      showCardType: false,
      idCardArr: [],

      orderNumber: '', // 山西活体认证订单号
      isAuth: false, // 是否点击认证按钮
      isMiniProgramed: false // 是否跳转小程序成功认证完成
    }
  },

  beforeRouteEnter (to, from, next) {
    // 进入当前组件监听路由 除一下路由之外 均需要提交数据   || from.name=='generatePerson'
    if (from.name == 'server') {
      to.meta.isBack = true
      next()
    } else {
      to.meta.isBack = false
      next()
    }
  },

  activated () {
    this.init()
  },

  methods: {
    // 页面初始化
    init () {
      if (!this.$route.meta.isBack) {
        this.getInfo()
      }
      this.submitStatus = 'default'
      this.vcodeLoading = false
      this.timer = null
      this.sendCodeText = 59
      this.formData.liveStatus = sessionStorage.getItem('liveStatus')
      this.$route.meta.isBack = true
    },
    /** ***********************************数据获取 --- start******************************************/

    getInfo () {
      // 认证结束后进入时 提交用户填写的信息
      if (this.$route.query.hasOwnProperty('liveStatus') && this.$route.query.liveStatus) {
        this.$nextTick(() => {
          this.$refs.formData.submit()
        })
      } else {
        this.mineInfo()
      }
    },

    mineInfo () {
      // 得到商户ID并通过商户ID获取商户信息
      this.merNo = localStorage.getItem('MERNO')

      const formData = new FormData()
      formData.append('merId', this.merNo)
      requestHTAPI.mineInfo(formData).then(res => {
        if (res.data.code == '0000') {
          const data = res.data.data
          data.isHmt = data.isHmt ? 1 : 0

          this.getJsSDK() // 公众号调小程序需JSSDK授权，由于现所有商户均走山西代征主体认证，暂将JSSDK授权提出至区分主体之外
          if (data.isLevyByQJX || data.isLevyByCSF) {
            // 山西||郴州
            this.idCardArr = [{ value: '身份证', id: 0 }]
          } else {
            // 其他
            this.idCardArr = [{ value: '身份证', id: 0 }, { value: '港澳台证件', id: 1 }]
          }

          if (data.id) {
            if (data.signUrl) {
              window.location.href = data.signUrl
            } else {
              this.formData = data
              this.isHmtText = this.idCardArr.find(res => res.id == data.isHmt).value
              this.idCardFrontPath = data.idCardFrontPath ? [{ url: data.idCardFrontPath, isImage: true }] : []
              this.idCardBackPath = data.idCardBackPath ? [{ url: data.idCardBackPath, isImage: true }] : []
              this.server = true
            }
          } else {
            this.server = !!this.$route.params.id
          }

          this.$set(this.formData, 'isLevyByQJX', data.isLevyByQJX)
          this.$set(this.formData, 'isLevyByCSF', data.isLevyByCSF)
          if (data.merNo != null) {
            this.merNo = data.merNo
          }
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    /** ***********************************数据获取 --- end******************************************/

    /** **********************************相关验证与信息提交--start **********************************/
    checkPhone (val) {
      return /^1[0-9]\d{9}$/.test(val)
    },
    // 证件类型
    getCardType (data) {
      this.formData.isHmt = data.id
      this.isHmtText = data.value
      this.showCardType = false
    },
    // 验证码
    sendVcode () {
      if (this.formData.mobile == '') {
        this.$notify({
          message: '请填写手机号码',
          position: 'top'
        })
        return
      }
      this.vcodeLoading = true
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.sendCodeText > 0) {
            this.sendCodeText--
          } else {
            this.sendCodeText = 59
            clearInterval(this.timer)
            this.vcodeLoading = false
            this.timer = null
          }
        }, 1000)
      }
      this.addMessageVO()
    },
    // 验证码请求
    addMessageVO () {
      requestHTAPI.addMessageVO({
        mobiles: this.formData.mobile,
        type: '0'
      }).then(res => {
        if (res.data.code === '0000') {
          this.$notify({ type: 'success', message: '短信验证码发送成功' })
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    onShowImg () {
      this.isShowImg = true
    },
    checkCanFace () {
      this.$nextTick(() => {
        this.submitStatus = 'goGP'
        this.$refs.formData.submit()
      })
    },

    // 山西活体认证
    getToken () {
      requestSXAPI.oauthToken({
        client_id: 'mp',
        client_secret: 'LetspMp888',
        grant_type: 'password',
        username: '14280010WK00311',
        password: '4ku41s5pks'
      }).then(res => {
        console.log(res, '获取山西活体认证TOKEN成功')
        sessionStorage.setItem('ACCESS_TOKEN', res.access_token)
        sessionStorage.setItem('TOKEN_TYPE', res.token_type)
        sessionStorage.setItem('EXPIRES_IN', res.expires_in)
        sessionStorage.setItem('REFRESH_TOKEN', res.refresh_token)
        sessionStorage.setItem('SCOPE', res.scope)
      })
    },

    onGoMiniProgramSuccess (e) {
      // const timer = setTimeout(() => {
      //   this.isMiniProgramed = true
      //   clearTimeout(timer)
      // }, 2000)

      const timer = setInterval(() => {
        const { name, idCard } = this.formData
        if (name && idCard && this.taxNo) {
          const data = {
            companyTaxNumber: this.taxNo,
            realName: name,
            idCard: idCard,
            access_token: sessionStorage.getItem('ACCESS_TOKEN')
          }
          requestSXAPI.getTpFaceVerifyResult(data).then(res => {
            console.log(res, '获取活体认证参数成功')
            if (res.code == '0') {
              const data = typeof res.data == 'string' ? JSON.parse(res.data) : res.data
              if (data?.identifyRet == 'Y' || data?.identifyRet == 'y') {
                this.$set(this.formData, 'liveStatus', 'true')
                this.isMiniProgramed = true
                clearInterval(timer)
              }
            } else {
              const newData = JSON.parse(JSON.stringify(data))
              newData.Interface = '/api/face/getTpFaceVerifyResult'
              newData.msg = res.msg
              requestHTAPI.saveErroMsg(newData).then(res => {
                if (res.data.code == '0000') {
                  console.log('错误信息记录成功')
                }
              })
            }
          })
        }
      }, 2000)
    },

    onGoMiniProgramError (e) {

    },

    onAuthEd () {
      this.isAuth = false

      const { name, idCard } = this.formData
      if (name && idCard && this.taxNo) {
        const data = {
          companyTaxNumber: this.taxNo,
          realName: name,
          idCard: idCard,
          access_token: sessionStorage.getItem('ACCESS_TOKEN')
        }
        requestSXAPI.getTpFaceVerifyResult(data).then(res => {
          console.log(res, '获取活体认证参数成功')
          if (res.code == '0') {
            this.isMiniProgramed = false
            const data = typeof res.data == 'string' ? JSON.parse(res.data) : res.data
            if (data?.identifyRet == 'Y' || data?.identifyRet == 'y') {
              this.$set(this.formData, 'liveStatus', 'true')
              this.submitStatus = 'default'
              this.$nextTick(() => {
                this.$refs.formData.submit()
              })
            } else {
              this.$notify({ type: 'danger', message: res.msg })
            }
          } else {
            const newData = JSON.parse(JSON.stringify(data))
            newData.Interface = '/api/face/getTpFaceVerifyResult'
            newData.msg = res.msg
            requestHTAPI.saveErroMsg(newData).then(res => {
              if (res.data.code == '0000') {
                console.log('错误信息记录成功')
              }
            })
            this.$notify({ type: 'danger', message: res.msg })
          }
        })
      }
    },

    submit () {
      debounce(() => { this.debounceSubmit() }, 500, false)
    },

    debounceSubmit () {
      if (!this.server) {
        this.$toast('用户服务协议未勾选')
        return false
      }

      // 提交数据如果没有商户id拦截
      if (!this.merNo) {
        this.$toast('未取得商户ID，请扫码后进入')
        return
      }

      if (this.submitStatus == 'goGP') {
        // 去认证
        if (this.formData.isLevyByQJX) {
          // 山西活体认证
          const { name, idCard } = this.formData
          const data = {
            companyTaxNumber: this.taxNo,
            realName: name,
            idCard: idCard,
            needQrCode: 'Y',
            idPhotoUp: this.idCardFrontPath.length === 1 && this.idCardFrontPath[0].hasOwnProperty('content') ? this.idCardFrontPath[0].content : '',
            idPhotoDown: this.idCardBackPath.length === 1 && this.idCardBackPath[0].hasOwnProperty('content') ? this.idCardBackPath[0].content : ''
          }
          // 展示加载提示
          this.$toast.loading({
            message: '加载中...',
            duration: 0,
            forbidClick: true
          })
          requestSXAPI.getFaceQRCode(data).then(res => {
            console.log(res, '获取活体认证参数成功')
            this.$toast.clear(true)
            if (res.code == '0') {
              const { orderNumber, qrCode } = res.data
              this.orderNumber = orderNumber
              this.isAuth = true
            } else {
              const newData = JSON.parse(JSON.stringify(data))
              delete newData.idPhotoUp
              delete newData.idPhotoDown
              newData.headers.Authorization = `${sessionStorage.getItem('TOKEN_TYPE')} ${sessionStorage.getItem('ACCESS_TOKEN')}`
              newData.Interface = '/api/face/getFaceQRCode'
              newData.msg = res.msg
              requestHTAPI.saveErroMsg(newData).then(res => {
                if (res.data.code == '0000') {
                  console.log('错误信息记录成功')
                }
              })
              this.$notify({ type: 'danger', message: res.msg })
            }
          })
        } else {
          // 字节活体认证
          this.$router.push({
            path: '/generatePerson',
            query: {
              name: this.formData.name,
              mobile: this.formData.mobile,
              idCard: this.formData.idCard,
              merId: this.merNo
            }
          })
        }
      } else {
        // 2022年10月8日14:40:59 暂时去掉山西强制人脸识别
        // 直接提交是给山西主体认证做拦截
        // if (this.formData.isLevyByQJX && this.formData.liveStatus != 'true') {
        //   this.$notify({ type: 'danger', message: '还未进行人脸识别，请认证' })
        //   return
        // }

        // 提交数据
        this.$toast.loading({
          message: '加载中...',
          duration: 0,
          forbidClick: true
        })
        const formData = new FormData()
        const data = this.formData
        for (const key in data) {
          formData.append(key, data[key])
        }
        formData.append('content', this.merNo)
        requestHTAPI.scanAddUser(formData).then(res => {
          this.$toast.clear(true)
          if (res.data.code === '0000') {
            this.$notify({ type: 'success', message: '操作成功' })
            if (res.data.data.hasOwnProperty('signUrl')) {
              window.location.href = res.data.data.signUrl
            } else {
              this.$router.push({
                path: '/taskHome'
              })
            }
          } else {
            this.$notify({ type: 'danger', message: res.data.message })
          }
        })
      }
    },

    /** **********************************相关验证与信息提交--end **********************************/

    /** ***********************************图片相关处理--start****************************************/
    // 删除图片
    beforeDelete (v) {
      requestHTAPI.delIdCardPic({
        id: this.formData.id,
        picType: v
      }).then(res => {
        if (res.data.code == '0000') {
          // this.$notify({ type: 'danger', message: res.data.message })
        } else {
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },

    // 上传处理
    afterRead (file, fileName) {
      this.files.name = file.file.name // 获取文件名
      this.files.type = file.file.type // 获取类型
      this.imgPreview(file.file, fileName)
    },

    // 转base图片
    imgPreview (file, fileName) {
      const self = this
      let Orientation
      Exif.getData(file, function () {
        Orientation = Exif.getTag(this, 'Orientation')
      })
      if (!file || !window.FileReader) return
      if (/^image/.test(file.type)) {
        // 创建一个reader
        const reader = new FileReader()
        // 将图片2将转成 base64 格式
        reader.readAsDataURL(file)
        // 读取成功后的回调
        reader.onloadend = function () {
          const result = this.result
          const img = new Image()
          img.src = result
          // 判断图片是否大于500K,是就直接上传，反之压缩图片
          if (this.result.length <= 500 * 1024) {
            // 上传图片c
            self.postImg(this.result, fileName)
          } else {
            img.onload = function () {
              const data = compress(img, Orientation)
              // 上传图片
              self.postImg(data, fileName)
            }
          }
        }
      }
    },

    // 上传
    postImg (base64, fileName) {
      const file = dataURLtoFile(base64, this.files)
      this.formData[fileName] = file
    },
    /** ***********************************图片相关处理--end****************************************/

    getJsSDK () {
      // 解决IOS客户端在history模式下签名失败问题
      let url = ''
      // 判断是否是ios微信浏览器
      if (window.__wxjs_is_wkwebview === true) {
        url = window.entryUrl.split('#')[0]
      } else {
        url = window.location.href.split('#')[0]
      }

      const formData = new FormData()
      const data = { url: url }
      for (const key in data) {
        formData.append(key, data[key])
      }

      requestHTAPI.jsJdk(formData).then(res => {
        console.log(res, 'JSSDK 权限注入成功')
        if (res.data.code == '0000') {
          const { data } = res.data
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: process.env.VUE_APP_BASE_APPID, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: ['checkJsApi'],
            openTagList: ['wx-open-launch-weapp'] // 微信开放标签 小程序跳转按钮：<wx-open-launch-weapp>
          })
          wx.ready(function () {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。

          })
        }
      })
    }
  },
  mounted () {
    this.getToken()
  }
}
</script>
<style scoped lang="less">
.form-layout{
  margin: 0 auto;
}
/deep/ .van-uploader__preview-image{
  width: 300px;
  height: 150px;
}
.btn-vcode{
  width: 210px;
  height: 60px;
}
.font-style{
  color: #2186ea;
}
.empty-label{
  padding-left: 4vw;
  width: 100%;
}

.businessLicense {
  padding: 2.67vw 4.27vw;
  color: #646566;
  text-align: left;
  font-size: 3.73vw;
  line-height: 6.4vw;
}
.pass-face{
  color: #04BE02;
}
.wargin-face{
  color: #d40505;
  font-size: 3.73vw;
}
.to-face{
  color: #04BE02;
  text-decoration: underline;
}

/deep/ .van-form {
  padding: 0 4vw 30px;
}

.modal-layer-popup-title{
  font-size: 32px;
  color: #333;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 60px 0 50px;
}
.modal-layer-popup-content{
  font-size: 24px;
  color: #666;
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: 0;
}
.modal-layer-popup-content span{
  color: red;
}
.van-dialog{
  border-radius:10px;
  -webkit-font-smoothing: antialiased;
}
.modal-layer-popup-number span{
  font-size: 60px;
  color: #333;
  display: inline-block;
  width: 100px;
  height: 120px;
  line-height: 120px;
  margin-right: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}
.modal-layer-popup-number{
  font-size: 0;
  text-align: center;
  margin-bottom: 80px;
}
</style>
